const Releases = () => {
  return <div className="s-releases">
		<a id="releases"><h2 className="container-fluid">
			<div className="col">RELEASES</div>
		</h2></a>
		<style>
</style>
<div className="container">
	<div className="row p-2">
		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-du-sollst-freisein.jpg" />
				<span className="card-title m-2">2019</span>
				<ul className="hh-track-list">
					<li>01 - Freisein</li>
					<li>02 - Willkommen</li>
					<li>03 - Licht</li>
					<li>04 - Wechsel</li>
					<li>05 - Tagtraum</li>
					<li>06 - Gefühl</li>
					<li>07 - Tagesstar</li>
					<li>08 - Taxi</li>
					<li>09 - Wahrheit</li>
					<li>10 - Türen</li>
					<li>11 - Rückenwind</li>
				</ul>
			</div>
		</div>



		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/202008-disc-nochnerunde-crop.jpg" />
				<span className="card-title m-2">2015</span>
				<ul className="hh-track-list">
					<li>01 - Retrospektive</li>
					<li>02 - Montag Morgen</li>
					<li>03 - Karussell</li>
					<li>04 - Weggeträumt</li>
					<li>05 - Ein Appell</li>
					<li>06 - Wer Nicht Tanzt</li>
					<li>07 - Schnaps</li>
					<li>08 - Moralisch Einwandfrei</li>
					<li>09 - Ab Ins Meer</li>
					<li>10 - Spuren Im Sand</li>
					<li>11 - Sexy Ferkel</li>
					<li>12 - Allüren</li>
					<li>13 - Pivo</li>
					<li>14 - Alte Liebe</li>
					<li>15 - Glockenspiel</li>
				</ul>
			</div>
		</div>

		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-aufstehen_tanzen.jpg" />
				<span className="card-title m-2">2011</span>
				<ul className="hh-track-list">
					<li>01 - Aufstehn Tanzen</li>
					<li>02 - Nimm Das Leben Leicht</li>
					<li>03 - Das Ist Bad Nenndorf</li>
					<li>04 - Lemminge</li>
					<li>05 - Schwarze Flagge</li>
					<li>06 - Frueher War Alles Lauter</li>
					<li>07 - Sehnsucht</li>
					<li>08 - Moderne Zeiten</li>
					<li>09 - Echter Mann</li>
					<li>10 - Glitzer Glitzer</li>
					<li>11 - Halts Aus</li>
					<li>12 - Salsa Maedchen</li>
					<li>13 - Nach Hause</li>
					<li>14 - Sonntagsdepressionen</li>
					<li>15 - Aufstehn Tanzen (Bratzen Zum Herrentanz Remix)</li>
				</ul>
			</div>
		</div>

		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-ichwillalles.jpg" />
				<span className="card-title m-2">2009</span>
				<ul className="hh-track-list">
					<li>01 - Intro</li>
					<li>02 - Schöne Julischka</li>
					<li>03 - Weißt Du Noch?</li>
					<li>04 - Die Sonne Brennt</li>
					<li>05 - Nur Pech</li>
					<li>06 - Der Perfekte Freund</li>
					<li>07 - Durchzechte Nächte</li>
					<li>08 - Damenhandballübertragungen</li>
					<li>09 - Ich Will Alles</li>
					<li>10 - Das Shuttle</li>
					<li>11 - Lass Uns Tanzen Gehen</li>
					<li>12 - Kurze Begegnung</li>
					<li>13 - Outro</li>
				</ul>
			</div>
		</div>

		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-wirluegennicht.jpg" />
				<span className="card-title m-2">2007</span>
				<ul className="hh-track-list">
					<li>01 - Lass Dich nicht...</li>
					<li>02 - Brocken</li>
					<li>03 - Wir lügen nicht</li>
					<li>04 - Spuren im Schnee</li>
					<li>05 - Rhythmus</li>
					<li>06 - Auf Kurs</li>
					<li>07 - Fliehkraft</li>
					<li>08 - Revolution</li>
					<li>09 - Wüstenlicht</li>
					<li>10 - Neuer Tag</li>
					<li>11 - Bereit</li>
					<li>12 - Helden meiner Jugend</li>
				</ul>
			</div>
		</div>
		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-anplackt.jpg" />
				<span className="card-title m-2">2006</span>
				<ul className="hh-track-list">
					<li>01 - Wüstenlicht</li>
					<li>02 - Beim Bier</li>
					<li>03 - Damenhandballübertragungen</li>
					<li>04 - Brückenschläfer</li>
					<li>05 - Mein liebster Freund</li>
				</ul>
			</div>
		</div>
		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-leskacestmoi.jpg" />
				<span className="card-title m-2">2005</span>
				<ul className="hh-track-list">
					<li>01 - Nun geht's los</li>
					<li>02 - Katzengrau</li>
					<li>03 - Keine Helden II</li>
					<li>04 - Wach Auf</li>
					<li>05 - Brückenschläfer</li>
					<li>06 - Ska</li>
					<li>07 - Kein Liebeslied</li>
					<li>08 - Langeweile</li>
					<li>09 - Cos Cochones</li>
					<li>10 - Zeile für Zeile</li>
					<li>11 - Lauf</li>
					<li>12 - Hey Oh</li>
					<li>13 - Tränen aus Pernod</li>
				</ul>
			</div>
		</div>
		<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2">
			<div className="card">
				<img className="card-img-top" src="static/gfx_sizes/300/disc-frueherwaralleslauter.jpg" />
				<span className="card-title m-2">2003</span>
			</div>
		</div>
	</div>
</div>

	</div>
}

export default Releases
