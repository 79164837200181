import React, {useState} from 'react'
import Gigservice, {GigDTO} from '../service/gigs'

enum Status {
  loading,
  ready
}

const RenderGigs = (props: {show?: string}) => {
  const gigs = (props.show === 'all') ? Gigservice.getAll() : Gigservice.getCurrent()
  return <div className="s-live">
		<a id="live"><h2 className="container-fluid">
			<div className="col">LIVE</div>
		</h2></a>
		<table className='gigTable'>
      <tbody>
			{
        gigs.map((g, i) => <RenderGig key={i} gig={g} />)
      }
      </tbody>
		</table>
    {
      window.location.href.search('allgigs') === -1 &&
      <a className="m-2" style={{display: "block", textAlign: "center"}}  href="/allgigs">Alle Konzerte</a>
    }
    {
      window.location.href.search('allgigs') > -1 &&
      <a className="m-2" style={{display: "block", textAlign: "center"}}  href="/">Zurück zur Hauptseite</a>
    }
	</div>
}

const RenderGig = (props: {gig: GigDTO}) => {
  const g = props.gig
  return <tr className="hh-gig">
      <td className="hh-gig-date">{g.getFormatedDate()}</td>
      <td className="hh-gig-name">
        <a href={g.url} >{g.venue}</a>
        &nbsp;@
        <a target="_blank" href={`https://www.google.de/maps/place/${g.street} ${g.streetaddress}, ${g.town}`}>
          <span className="hh-gig-city">{g.town}</span>
        </a>
      </td>
    </tr>
}

const Live = (props: {show?: string}) => {
  const [status, setStatus] = useState(Status.loading)
  Gigservice.get().then(() => {
    setStatus(Status.ready)
  })
  switch(status) {
    case Status.loading:
      return <div>Lade Gigs</div>
    case Status.ready:
      return <RenderGigs show={props.show} />
  }
}

export default Live
