import Singlepage from './singlepage'
import Impressum from './impressum'
import Live from './live'


const RenderContent = () => {
  const pathParts = window.location.pathname.split('/')
  if (pathParts.length > 2) {
    window.location.href = '/' + pathParts[1]
    return null
  }
  switch(pathParts[1]) {
    case 'impressum':
      return <Impressum />
    case 'allgigs':
      return <Live show={'all'} />
    default:
      return <Singlepage />
  }
}

const Main = () => {
  return <>
    <div id="container-fluid">
      <div data-spy="scroll" data-target="#navi" data-offset="0">
        <RenderContent />
      </div>
      <hr />
    </div>
    <div className="footer container-fluid">
      <div className="row justify-content-md-center">
        <span className="col-md-auto align-middle lH100px">&copy; 2020 BAD NENNDORF BOYS</span>
        <span className="col-md-auto align-middle bnblogo"></span>
        <span className="col-md-auto align-middle">SKA-PUNK SINCE 2002</span>
      </div>
    </div>
    {
      window.location.href.search('impressum') === -1 &&
      <a href="/impressum" className="pl-3 pb-3">Impressum und Datenschutz</a>
    }
    {
      window.location.href.search('impressum') > -1 &&
      <a href="/" className="pl-3 pb-3">Zurück zur Hauptseite</a>
    }
  </>
}

export default Main
