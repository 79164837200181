const Impressum = () => {
  return <div className='container'>
    <div className="row w3-padding">

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Booking</h3>
		<img src="/static/gfx_sizes/300/Logo_Booking.png" alt="Solar Penguin Logo" width="100%"  />
		<div className="hh-address">
			Solar Penguin Agency<br />
			Hungener Straße 5<br />
			60389 Frankfurt/M <br />
			Tel. +49 (0)69-256269-60<br />
			Fax. +49 (0)69-256269-77 <br />
			<a href="https://www.solarpenguin.de">https://www.solarpenguin.de</a>
		</div>
	</div>
</div>

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Vertrieb</h3>
		<img src="/static/gfx_sizes/300/Logo_Vertrieb.png" alt=" " width="50%" />
		<div className="hh-address">
			BROKEN SILENCE Independent Distribution GmbH<br />
			Oberhafen-Kontor, Stadtdeich 27<br />
			20097 Hamburg / Germany<br />
			Tel.: 040/32 52 85-0<br />
			Fax: 040/32 52 85-20<br />
			<a href="http://www.brokensilence.biz/" target="_blank">http://www.brokensilence.biz/</a>
		</div>
	</div>
</div>

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Technical Rider und Presse</h3>
		<div><a href="/bnboys/documents/technical_rider_bnb_2019/technical_rider_bnb_2019.pdf">Technical Rider</a></div>
		<div><a href="/bnboys/documents/BNB_Presskit.zip">Pressekit</a></div>
	</div>
</div>

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Haftungsausschluss</h3>
		<div className="hh-address">
			<p>Die bereitgestellten Informationen auf der Website www.bnboys.de wurden sorgfältig geprüft und werden regelmäßig aktualisiert. Jedoch kann keine Haftung oder Garantie dafür übernommen werden, dass alle Angaben zu jeder Zeit vollständig, richtig und in letzter Aktualität dargestellt sind.
			Dies gilt insbesondere für alle Verbindungen ("Links") zu anderen Websites, auf die direkt oder indirekt verwiesen wird. Hinsichtlich der Links erkläre ich ausdrücklich, dass ich keinerlei Einfluss auf die Gestaltung und die Inhalte der verlinkten Seiten habe und ich mir diese Inhalte nicht zu Eigen mache. Diese Erklärung gilt für alle auf der Website www.bnboys.de angezeigten Links.</p>
		</div>
	</div>
</div>

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Urheber- und Kennzeichenrecht</h3>
		<div className="hh-address">
			<p>Die Bad Nenndorf Boys sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, von den Bad Nenndorf Boys selbst erstellte Objekte bleiben allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist nur nach schriftlicher Genehmigung gestattet.</p>
		</div>
	</div>
</div>

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Datenschutz</h3>
		<div className="hh-address">
			<p>Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Alle Daten werden von uns vertraulich behandelt und nicht an Dritte weitergegeben. Sie dienen allein internen Zwecken wie Newsletter, Informationsanforderungen etc.</p>
			<p>Diese Seite verwendet keine Cookies und wir sammeln keinerlei Daten. Beim Nutzen der YouTube Clips haben wir allerdings keinen Einfluss, welche Daten erhoben werden und wo sie hingeleitet werden. Daher muss das abspielen dieser Clips erst von Dir genemigt werden!</p>
		</div>
	</div>
</div>

<div className="card col-sm-12 col-md-6 col-12">
	<div className="hh-inner">
		<h3>Webadministrator</h3>
		<div className="hh-address">
		<p>
Hauke Huchzermeyer<br />
Kampstraße 71<br />
30629 Hannover
</p>
<p>
  Telefon: 0151 58118427<br />
  E-Mail: <span className="firstname">hauke</span><span className="kringel">_at_</span>bnboys.de<br />
  Umsatzsteuer-Identifikationsnummer (USt-IdNr.): DE321087074<br />
</p>
<p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
		</div>
	</div>
</div>

</div>
</div>
}
export default Impressum
