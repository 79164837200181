import axios from 'axios'
import config from '../config.json'

const twoDigits = (d: number): string => {
  if (d < 10) {
    return `0${d}`
  }
  return `${d}`
}
export class GigDTO {
  date: Date = new Date()
  title: string = ''
  description: string = ''
  postcode: string = ''
  street: string = ''
  town: string = ''
  url: string = ''
  venue: string = ''
  streetaddress: string = ''
  constructor (g: IGig) {
    this.date = new Date(g.date)
    this.title = g.title
    this.description = g.description
    this.postcode = g.postcode
    this.street = g.street
    this.town = g.town
    this.url = g.url
    this.venue = g.venue
    this.streetaddress = g.streetaddress
  }
  isCurrent () {
    const now = new Date()
     now.setDate(now.getDate() -1)
    return (this.date > now)
  }
  getFormatedDate () {
    return `${twoDigits(this.date.getDate())}.${twoDigits(this.date.getMonth() + 1)}.${this.date.getFullYear()}`
  }
}

class GigService {
  gigs: GigDTO[] = []
  constructor() {

  }
  async getFromServer () {
    const raw = await axios.get(`${config.apiPrefix}gigs`)
    console.log('gigs', raw)
    this.gigs = raw.data.map((g: IGig) => new GigDTO(g)).sort((a: GigDTO, b: GigDTO) => a.date.getTime() - b.date.getTime())
  }
  async get() {
    if(this.gigs.length === 0) {
      await this.getFromServer()
    }
  }
  getCurrent() {
    return this.gigs.filter(g => g.isCurrent())
  }
  getAll() {
    return this.gigs.reverse()
  }
}

interface IGig {
  description: string
  postcode: string
  street: string
  town: string
  url: string
  venue: string

  date: string
  title: string
  created_at: string
  deleted_at: string
  id: number
  published: number
  status: any
  streetaddress: string
  updated_at: string

}

const gigService = new GigService()
export default gigService
