import React, {useState} from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

type Video = {
  original: string,
  embedUrl: string
}

const RenderVideo = (item: any) => {
  return <div className='video-wrapper'>
    <iframe
      width='560'
      height='315'
      src={item.embedUrl}
      frameBorder='0'
      allowFullScreen
    >
    </iframe>
  </div>
}

const videos: Video[] = [
  {key: 'm6ubUAGb4Qk'},
  {key: 'h6C0hYZYNjU'},
  {key: '2LfqKzosA1Y'},
  {key: 'OccjnuuwBc0'},
  {key: 'xkwnpWK8GU8'},
  {key: '4NuhEDrxmKo'},
  {key: 'LmU60gP_FVY'},
  {key: 'dXH9JQMLiv0'},
  {key: 'fXDxs6mC7tc'},
  {key: 'ebvdlIkqM5g'},
  {key: 'AD2BIy4rh3o'},
  {key: 'dNOr1Z793E8'},
].map(v => {
    return {
      original: `https://i3.ytimg.com/vi/${v.key}/maxresdefault.jpg`,
      embedUrl: `https://www.youtube-nocookie.com/embed/${v.key}`,
      renderItem: RenderVideo.bind(v)
    }
  }
)


const Videos = () => {
  const [userSaidYes, setUserSaidYes] = useState(false)
  if (!userSaidYes) {
    return <div className="s-videos">
        <a id="videos">
          <h2 className="container-fluid">VIDEOS</h2>
        </a>
        <div className="video-disclaimer">
          <p>
            Bei Nutzung von Youtube können Daten von Youtube, Google und Dritten gesammelt werden. Wenn das für Dich okay ist, dann klicke
          </p>
          <p>
            <button className="video-disclaimer-button"
              onClick={() => {
                setUserSaidYes(true)
              }}
            >hier</button>
          </p>
        </div>
      </div>
  }
  return <div className="s-videos">
		<a id="videos">
      <h2 className="container-fluid">VIDEOS</h2>
    </a>
		<div className="pb-3">
      <ImageGallery
        items={videos}
        showFullscreenButton={false}
        showPlayButton={false}
        showBullets={true}
        lazyLoad={true}
        />
    </div>
  </div>
}

export default Videos
