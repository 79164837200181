const Booking = () => {
  return <div className="s-booking">
		<a id="booking"><h2 className="container-fluid">
			<div className="col">BOOKING</div>
		</h2></a>
<div className="container">
	<div className="row">

<div className="col p-2 col-sm-12 col-md-4 col-12">
	<div className="card">
		<div className="bookingHeaderLogo card-img-top" style={{backgroundImage: "url(static/gfx/Logo_Booking.png)"}}></div>
		<h3 className="card-title pl-4">Booking</h3>
		<div className="card-body">
			Solar Penguin Agency<br />
			Hungener Straße 5<br />
			60389 Frankfurt/M <br />
			Tel. +49 (0)69-256269-60<br />
			Fax. +49 (0)69-256269-77 <br />
			<a href="https://www.solarpenguin.de">https://www.solarpenguin.de</a>
		</div>
	</div>
</div>

<div className="col p-2 col-sm-12 col-md-4 col-12">
	<div className="card">
		<div className="bookingHeaderLogo card-img-top" style={{backgroundImage: "url(static/gfx/Logo_Vertrieb.png)"}}></div>
		<h3 className="card-title pl-4">Vertrieb</h3>
		<div className="card-body">
			BROKEN SILENCE Independent Distribution GmbH<br />
			Oberhafen-Kontor, Stadtdeich 27<br />
			20097 Hamburg / Germany<br />
			Tel.: 040/32 52 85-0<br />
			Fax: 040/32 52 85-20<br />
			<a href="http://www.brokensilence.biz/" target="_blank">http://www.brokensilence.biz/</a>
		</div>
	</div>
</div>

<div className="col p-2 col-sm-12 col-md-4 col-12">
	<div className="card">
		<div className="bookingHeaderLogo card-img-top" style={{backgroundImage: "url(static/gfx/Logo_Label.png)"}}></div>
		<h3 className="card-title pl-4">Label</h3>
		<div className="card-body">
			SUNNY BASTARDS<br />
			Brosweg 8<br />
			45239 Essen / Germany<br />
		</div>
	</div>
</div>

<div className="col p-2 col-sm-12 col-md-12 col-12">
	<div className="card">
		<h3 className="card-title pl-4">Technical Rider und Presse</h3>
		<div className="card-body">
			<div><a href="/downloads/technical_rider_bnb_2019/technical_rider_bnb_2019.pdf">Technical Rider</a></div>
			<div><a href="/downloads/BNB_Presskit.zip">Pressekit</a></div>
		</div>
	</div>
</div>

</div></div>

	</div>
}

export default Booking
