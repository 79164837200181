import Live from './live'
import Releases from './releases'
import Videos from './videos'
import Booking from './booking'
const Singlepage = () => {
  return <>
    <LALATeaser />
    <Live />
    <Releases />
    <Videos />
    <Booking />
  </>
}

export default Singlepage

function LALATeaser() {
  if (new Date() < new Date('2024-05-13')) {
    return <div className='w3-row'>
    <img alt='flyer' className='w100' src='static/gfx/2024-05-11-240313_WeBr24_A4.jpg' />
  </div>
  }
  return null
  return <div className='w3-row'>
    <img className='w100' src='static/gfx/IMG-20231212-WA0000.jpg' />
  </div>
}
